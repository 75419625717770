.clear-filters-icon {
	cursor: pointer;
	border-radius: 50%;
	color: #5c5c5c;
	padding: 5px;
}

.clear-filters-icon:hover {
	background-color: rgba(0, 0, 0, .1);
	color: #000;
}