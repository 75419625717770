.notification-icon {
  background-color: transparent;
  cursor: pointer;
  padding: 5px;
  border-radius: 100%;
}

.notification-icon:hover {
  background-color: #ffe300;
}

.notification-count {
  width: 20px;
  height: 20px;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 100%;
  background-color: rgb(245, 66, 66);
  position: absolute;
  font-size: small;
  top: 0;
  right: 0;
}
