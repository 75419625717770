.fc .fc-col-header-cell-cushion {
  color: gray;
  text-decoration: none;
  cursor: default;
}
.fc .fc-col-header-cell-cushion::first-letter {
  text-transform: uppercase;
}

.fc .fc-daygrid-day-number {
  color: black;
  text-decoration: none;
  cursor: default;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.fc-daygrid-day-top {
  justify-content: center;
}

.modal {
  display: flex;
  height: 100%;
}

.fc-daygrid-event {
  white-space: normal !important;
  align-items: normal !important;
}

.btn-create {
  background-color: var(--primary-color);
  padding: 5px 25px;
  font-size: 19px;
  border-radius: 15px;
  margin-left: 10px;
  height: min-content;
  width: max-content;
}

.button-kanban {
  background-color: #2d9cdb;
  height: min-content;
  color: white;
  border: none;
  border-radius: 15px;
  padding: 5px 36px 5px 30px;
  font-size: 19px;
  margin-left: 10px;
}

.rowClassName {
  background-color: #cccc;
  border-radius: 20px;
  border-width: 3px;
  border-style: solid;
  font-weight: bold;
  cursor: pointer;
}

.rowClassName td:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.rowClassName td:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.rowExpandedClassName {
  border-left-width: 100px;
  border-left-color: #2d9cdb;
  cursor: pointer;
}
