.header-form-create-task {
  display: grid;
  grid-template-columns: 6fr 1fr 1fr;
  column-gap: 12px;
  align-items: flex-end;
}

.label-input-file {
  border: 1px solid #ffe300;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px !important;
  color: #ffe300;
  cursor: pointer;
  border-radius: 20px;
}

.input-file {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.div-item-file {
  width: 150px;
  height: 150px;
  border: 1px solid #ffe300;
  border-radius: 20px;
  position: relative;
}

.div-item-file img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.close-button-file {
  right: 7px;
  color: red;
  cursor: pointer;
}

.ant-upload.ant-upload-select-picture-card {
  width: 100% !important;
  min-height: 150px;
}

.upload-file {
  color: #9e9e9e;
}

.upload-icon {
  font-size: 40px;
  margin-bottom: 10px;
}

.upload-disable {
  pointer-events: none !important;
  cursor: default !important;
  opacity: 0.7 !important;
}
