.kanban-container {
  display: flex;
}

.kanban-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.kanban-card {
  user-select: none;
  padding: 9px 6px;
  margin: 0 0 8px 0;
  min-height: 140px;
  min-width: 360px;
  color: black;
  border-radius: 17px;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
  font-size: 14px;
}

.kanban-title {
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

.kanban-tasks-list {
  margin: 8;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 7px;
}

.editStatus {
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
}
.kanban-new-list {
  padding: 9px 10px 9px 8px;
  margin: 0 10px 12px 20px;
  width: max-content;
  color: #2d9bdb;
  border-radius: 15px;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
  font-size: 15px;
  font-weight: 500;
  background-color: #53c0ff51;
}

.kanban-new-list:hover {
  color: white;
  background-color: #2d9cdb;
  transition: 0.5s;
}

.kanban-info-title {
  display: flex;
  margin-left: 12px;
  margin-bottom: 2px;
}

.edit-column-button:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d0d4db90;
  width: 25px;
  height: 25px;
  cursor: pointer;
  border-radius: 5px;
}
