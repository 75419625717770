.inventory__filters--search {
  background-color: transparent;
  border-radius: 5px !important;
}

.inventory {
  animation: fade-in 0.2s ease;
}

.inventory__title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inventory__title--icon {
  margin-bottom: 10px;
  margin-right: 9px;
}

.inventory__filters {
  margin-top: 20px;
  display: grid;
  /* grid-template-columns: 5fr 3fr 1fr; Retornar quando o filtro do select for implementado */
  grid-template-columns: 9fr 1fr;
  grid-column-gap: 10px;
  background-color: white;
}

.inventory__filters--select > div {
  border-radius: 5px !important;
}

.inventory__icons {
  font-size: 22px;
  color: #4f4f4f;
}

@keyframes fade-in {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
