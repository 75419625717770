.padding-link-logo {
  padding: 30px 35px;
  position: relative;
}

.submenu {
  position: fixed;
  top: 0;
  left: 111px;
  background-color: white;
  height: 100%;
  padding: 38px !important;
  min-width: 300px;
  display: none;
  z-index: 3000;
  overflow-y: auto;
}

.has-submenu:hover > .submenu {
  display: block;
}

.sidenav-svg {
  fill: var(--primary-color);
}

.div-navlink {
  color: var(--primary-color);
}

.div-navlink:hover {
  transition: all 0.5s;
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.div-navlink:hover .sidenav-svg {
  fill: var(--secondary-color);
}

.text-black {
  color: black !important;
}

.btn-projects {
  background-color: transparent;
  border: none;
  display: block;
}

.btn-projects:hover {
  text-decoration: underline;
}

.active-project {
  font-weight: bold;
}

.active-project::before {
  content: "\2022";
  position: absolute;
  color: var(--primary-color);
  left: 80px;
}

.separator-div {
  height: 2px;
  width: 30px;
  background-color: black;
  margin: 35px 0 15px 0;
}
