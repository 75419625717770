.card-project {
  background-color: white;
  border-radius: 15px;
  box-shadow: 1px 1px 10px #b3b1b1;
  cursor: pointer;
}

.card-project:hover {
  background-color: #f3f3f3;
}
