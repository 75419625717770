#ck-button {
  margin: 4px;
  background: var(--primary-color);
  border-radius: .25rem;
  overflow: auto;
  width: fit-content;
}

#ck-button:hover {
  background: black;
  color: var(--primary-color);
}

#ck-button label {
  margin: 0;
  cursor: pointer;
}

#ck-button label span {
  text-align: center;
  display: block;
  padding: 13px 60px;
}

#ck-button label input {
  position:absolute;
  top:-20px;
}

#ck-button input:checked + span {
  background-color: black;
  color: var(--primary-color);
}
