.card-container {
  height: 200px;
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 100%;
}

.card-line {
  width: 6px;
  background-color: #4f4f4f;
  height: 100%;
}

.card-circle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-left: -12px;
}

.card-left-side {
  display: flex;
  height: 100%;
  align-items: center;
}

.paper-card {
  margin-left: 34px;
  background: #ffffff;
  box-shadow: 0px 4px 7px #e5e5e5;
  border-radius: 5px;
  padding: 16px;
  min-height: 84px;
  display: grid;
  grid-template-columns: 3fr 3fr 1fr;
  column-gap: 15px;
  width: 100%;
}

.paper-card-info {
  display: flex;
  flex-direction: column;
}

.paper-card-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
  color: #4f4f4f;
  font-size: 12px;
  margin: 0px 10px;
}

.color-blue {
  color: #2d9cdb;
}

.color-blue > svg {
  fill: #2d9cdb;
}

.paper-card-span {
  margin-top: 3px;
}

.actions-icon {
  font-size: 20px;
}

.card-row-info {
  margin-top: 5px;
}

.paper-card-chips {
  display: flex;
}
