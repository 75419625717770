.default-button:disabled {
	border-color: #ccc !important;
	background-color: #ccc !important;
}

.user-profile-button {
	min-width: 200px;
}

.user-profile-button:hover {
	border-color: #dfc500 !important;
	background-color: #dfc500 !important;
}

.user-profile-button-selected {
	background-color: #000 !important;
	border-color: #000 !important;
	color: #ffe300 !important;
	cursor: default !important;
}

.user-profile-button-selected:hover {
	background-color: #000 !important;
	border-color: #000 !important;
}

.form-input>input {
	cursor: not-allowed !important;
}

.projects-div {
	max-height: 150px;
	overflow-y: auto;
}

.disassociate-button {
	color: #fff !important;
	background-color: #2d9cdb !important;
}

.disassociate-button:hover {
	color: #fff !important;
	background-color: #1a79af !important;
}