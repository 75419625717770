.textAreaPlaceholder {
	font-weight: bold;
	font-size: 14px !important;
}

.dark-button {
	background-color: #525252 !important;
	color: #fff !important;
	font-weight: bold !important;
}

.dark-button:hover {
	background-color:  #383838 !important;
}

.dark-button:hover:disabled {
	background-color: #ccc !important;
}

.export-to-pdf-button {
	background-color: #ff8989 !important;
}

.export-to-pdf-button:hover {
	background-color: #ff5454 !important;
}

.export-to-pdf-button:hover:disabled {
	background-color: #ccc !important;
}
