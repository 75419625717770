.kanban-card {
  user-select: none;
  padding: 9px 16px;
  margin: 0 0 8px 0;
  min-height: 145px;
  min-width: 360px;
  color: black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cursor-pointer {
  cursor: pointer;
}

.card-name::first-letter {
  text-transform: uppercase;
}

.description {
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.description::first-letter {
  text-transform: uppercase;
}

.card-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
