.listAllLogs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.listAllLogs li {
  margin-bottom: 5px;
}

.item-row {
  justify-content: center;
  background-image: linear-gradient(to left, #ffffff 96%, #ffe300 100%);
  border-radius: 18px;
  display: flex;
  margin: auto;
  justify-content: flex-start;
  width: 60vw;
  position: relative;
  cursor: pointer;
}
.icons {
  font-size: 32px;
}

.notification-date {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 10px;
}

.item-row p {
  padding-left: 20px;
  overflow: hidden;
  flex-wrap: wrap;
}
