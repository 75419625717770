.detail-label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  /* Cinza fonte */

  color: #4f4f4f;
}

.card-detail-description {
  max-width: 500px;
}
