#form-container-div {
	padding: 15px 0px;
	width: 1024px;
}

#cancel-button {
	color: #4d4d4d;
	text-decoration: underline;
}

#cancel-button:hover {
	font-weight: bold;
}

@media screen and (max-width: 1023px) {
	#form-container-div {
		padding: 15px 5px;
		width: 100%;
	}
}