.images-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5px;
}

.drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.drawer-close:hover {
  text-decoration: underline;
  cursor: pointer;
}

.drawer-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  margin-right: 20px;
  -webkit-box-orient: vertical;
}

.mb-none {
  margin-bottom: 0;
}

.drawer-edit {
  margin: 2px;
  z-index: 1;
}

.drawer-details {
  position: relative;
}
