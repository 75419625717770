.input-danger {
  border-color: red !important;
  color: red !important;
}

.input-div {
  border: 1px solid #9e9e9e;
  position: relative;
}

.input-div .span-input {
  font-size: 12px;
  position: absolute;
  top: -10px;
  left: 8px;
  padding: 0 3px;

  display: flex;
  align-items: center;
}

.input-div input {
  color: black;
}

.input-div input:focus {
  outline: none;
}

.disabled-input {
  background-color: #f3f3f3 !important;
  cursor: not-allowed !important;
  padding: 0;
}

.span-icon {
  margin-left: 5px;
  font-size: 18px;
}

.p-error {
  font-size: 10px;
}
