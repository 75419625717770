.button-kanban {
  background-color: #2d9cdb;
  color: white;
  border: none;
  border-radius: 15px;
  padding: 5px 36px 5px 30px;
  font-size: 19px;
}

.button-kanban span {
  margin-right: 10px;
}

.div-kanban {
  overflow-x: scroll;
  overflow-y: hidden;
  max-width: 90vw;
}

.header-form-kanban {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  column-gap: 20px;
}

.btn-create {
  background-color: var(--primary-color);
  padding: 5px 25px;
  font-size: 19px;
  border-radius: 15px;
}

.btn-dashboard {
  background-color: var(--blue-color);
  color: white;
  padding: 5px 25px;
  font-size: 19px;
  border-radius: 15px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.btn-dashboard:hover {
  text-decoration: none;
}

.project__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-projects {
  margin-top: 20px !important;
}

.btn-title {
  margin-left: 10px;
}
