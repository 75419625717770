.project {
  font-size: 11px;
}

.dropdown-toggle-container {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.input-danger {
  border-color: red !important;
  color: red !important;
}

.input-div {
  border: 1px solid #9e9e9e;
  position: relative;
}

.input-div .span-input {
  font-size: 12px;
  position: absolute;
  top: -10px;
  left: 8px;
  padding: 0 3px;
}

.input-div select {
  color: black;
  padding: 9.6px;
}

.input-div select:focus-visible {
  outline: none;
}

.select-disabled {
  cursor: not-allowed !important;
}

.p-error {
  font-size: 14px;
}
