.input-wrapper span {
  padding-bottom: 3px;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}

.icon {
  padding: 5px;
}

.search-input {
  height: 31px !important;
  background-color: transparent !important;
}

.search-input:focus {
  border-color: #ced4da !important;
}
