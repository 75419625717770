* {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary-color: #ffe300;
  --secondary-color: #000000;
  --red-color: #ff4c41;
  --blue-color: #2d9cdb;
  --red-filter: invert(51%) sepia(43%) saturate(5020%) hue-rotate(333deg)
    brightness(98%) contrast(105%);
  --blue-filter: invert(56%) sepia(84%) saturate(1318%) hue-rotate(173deg)
    brightness(88%) contrast(95%);
}

.btn-default {
  background-color: var(--primary-color) !important;
  width: 100%;
  height: 51px;
  border-radius: 0.25rem;
  color: black !important;
  font-size: 18px;
  border: 0 !important;
}

.btn-default:hover {
  background-color: #ead100 !important;
}

.btn-default:disabled {
  background-color: #ccc !important;
}

.h-100v {
  min-height: 100vh;
}

.text-primary-color {
  color: var(--primary-color) !important;
}

.bg-secondary-color {
  background-color: var(--secondary-color);
}

.cursor-pointer {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 22px;
  height: 22px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
