.detailed-report-row {
    cursor: pointer;
}

.modal-field-img {
    max-width: 800px;
    max-height: 600px;
}

.link-like-button {
    text-decoration: underline;
    color: blue;
}

.link-like-button:hover {
    color: #0044ff;
}

.export-to-excel-button {
    display: flex !important;
    align-items: center !important;
    background-color: #185c37 !important;
    border-color: #185c37 !important;
    color: #fff !important;
}

.export-to-excel-button:hover {
    border-color: #21a366 !important;
    background-color: #21a366 !important;
    color: #fff !important;
}