.ata-participant-card-container {
  height: 80px;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 1px 1px 7px RGBA(0, 0, 0, 0.3);
}

.ata-participant-card-avatar-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ata-participant-card-body-col {
  display: flex;
  align-items: center;
  padding-left: 0px;
}

.ata-participant-card-name {
  margin: 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ata-participant-card-role {
  margin: 0;
  font-size: 14px;
  width: 100%;
  line-height: 15px;
  height: 30px;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
  font-style: italic;
}

.ata-participant-card-done-icon-col {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0;
}

.ata-participant-card-delete-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0;

  color: #585858;
}
