.card-chart {
  border-radius: 10px;
  box-shadow: 0px 2px 12px #c3c3c3;
}

.header-form-dashboard {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
}

.div-labels-dashboard {
  margin: 0 50px 0 100px;
}

.pie-item-chart {
  width: 230px;
}

.priority_tag {
  border-radius: 30px;
  margin: 0;
  padding: 3px 0;
  text-align: center;
  color: #fff;
  font-size: 12px;
  width: 100%;
}

.project-icon > svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}
.report-icon > svg {
  width: 20px;
  height: 20px;
}

.no-border {
  border: 0px !important;
}

@media only screen and (max-width: 1325px) {
  .div-labels-dashboard {
    margin: 0px 20px 0px 5px;
  }

  .pie-item-chart {
    width: 169px;
  }
}

.Embed-container {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  height: 75vh;
  width: 90vw;
}
