.message-text {
  margin-top: 12px;
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  overflow-wrap: break-word;
}

.message-date-footer {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #4f4f4f;
}

.separator {
  border: 1px solid #4f4f4f;
}
