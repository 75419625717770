.card-container {
  height: 180px;
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 100%;
}

.card-line {
  width: 6px;
  background-color: #c0c0c0a4;
  height: 100%;
}

.card-circle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-left: -12px;
}

.card-left-side {
  display: flex;
  height: 100%;
  align-items: center;
}

.paper-card {
  margin-left: 34px;
  background: #ffffff;
  box-shadow: 0px 4px 7px #e5e5e5;
  border-radius: 5px;
  padding: 16px;
  min-height: 84px;
  display: grid;
  grid-template-columns: 2fr 2fr 4fr 0fr;
  column-gap: 15px;
  width: 100%;
}

.paper-card-info {
  display: flex;
  flex-direction: column;
}

.color-blue {
  color: #2d9cdb;
}

.color-blue > svg {
  fill: #2d9cdb;
}

.paper-card-span {
  margin-top: 3px;
}

.actions-icon {
  font-size: 20px;
}

.card-row-info {
  margin-top: 5px;
}

.paper-card-chips {
  display: flex;
}
