.row-item {
  justify-content: center;
  border-radius: 10px;
  max-height: 100px;
  display: flex;
  font-size: small;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
  font-weight: 400;
  color: grey !important;
  padding-left: 20px;
  overflow: hidden;
  flex-wrap: wrap;
}
