.subproject-card {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
