.rowClass {
  background-image: linear-gradient(to left, #ccc 96%, #ffe300 100%);
  border-radius: 20px;

  font-weight: bold;
  cursor: pointer;
}

.rowClass td:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.rowClass td:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.rowExpandedClass {
  border-left-width: 100px;
  border-left-color: #2d9cdb;
  cursor: pointer;
}
