.row-status {
  background: var(--blue-color);
  border-radius: 5px;
  color: white;
  font-weight: 600;
  height: 51px;
}

.row-status-disabled {
  background: #cccccc;
  color: black;
  border-radius: 5px;
  font-weight: 600;
  height: 51px;
}

.row-status p {
  margin: 0;
}

.dropup-content {
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  position: absolute;
  bottom: 65px;
  right: 7px;
  background-color: #f1f1f1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-bottom: 10px;
}

.dropup-content p {
  cursor: pointer;
  margin: 0;
  color: black;
  padding: 10px;
  text-decoration: none;
  font-size: 16px;
}

.dropup-content p:hover {
  background-color: #ddd;
  border-radius: 10px;
}

.dropup:hover .dropup-content {
  display: block;
}

.concluded-status {
  background: #8cd26b;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}

.concluded-status p {
  margin: 0;
}
