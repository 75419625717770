.rate-task {
  background: var(--primary-color);
  color: black;
  border-radius: 5px;
  font-weight: 600;
  height: 51px;
  margin-bottom: 10px;
}

.star-content {
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  position: absolute;
  bottom: 130px;
  right: 88px;
  padding-bottom: 10px;
  width: 200px;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-bottom: 10px;
}

.star-content p {
  margin: 0;
  color: black;
  padding: 5px 10px 5px;
}

.confirm-rate-button {
  background: var(--primary-color);
}
